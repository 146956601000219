@if ({ games: games$ | async }; as scope) {
  <div
    class="position-relative dropdown-select"
    [class.dropdown-dark]="scope.games?.length > 0"
    ngbDropdown
    [autoClose]="false"
    placement="bottom">
    <ultra-navbar-search-form
      [pending]="pending"
      (searchTrigger)="getGames($event)"
      (resetTrigger)="reset()"
      (submitTrigger)="navigateToSearchPage($event)" />
    <div class="invisible position-absolute w-100" ngbDropdownToggle></div>
    @if (!isSearchBarReset) {
      <div class="search__dropdown bg-grey-dark" ngbDropdownMenu aria-labelledby="dropdownConfig">
        <div ultraScroll class="scroll-container">
          @if (scope.games?.length === 0) {
            <div class="text-ultra-purple-light ls-quoter f-size-12 ps-3 pe-3" i18n>
              Oops! We couldn’t find any results.
            </div>
          }
          @for (game of scope.games; track game) {
            <a class="dropdown-item game cursor-pointer w-100" [routerLink]="['/store/games', game.slug, game.id]">
              <div class="img-placeholder game__poster rounded position-relative">
                <img [src]="game.getPreview() | resizeImage: 206 : 80" [alt]="game.title" />
              </div>
              <div
                class="game__name f-size-12 fw-bold ls-quoter text-capitalize text-truncate"
                ultraAutotestAttribute="navbar-search-game-name">
                {{ game.title }}
              </div>
            </a>
          }
        </div>
      </div>
    }
  </div>
}
