@if (currentValue) {
  <div ngbDropdown class="d-inline-block dropdown-dark dropdown-select dropdown-top">
    <button
      class="btn ls-quoter gap-8 {{ additionalClass }}"
      ultraAutotestAttribute="orderByButton"
      id="orderByButton"
      ngbDropdownToggle>
      @if (prefixIcon()) {
        <i class="icon {{ prefixIcon() }}"></i>
      }
      {{ currentValue.label }}
      <i class="icon icon-chevron-sm ms-auto"></i>
    </button>
    <div ngbDropdownMenu aria-labelledby="orderByButton">
      @for (value of values; track value) {
        <button class="dropdown-item" (click)="onSelectValue(value)">
          @if (currentValue.value === value.value) {
            <i class="icon icon-small-check"></i>
          }
          {{ value.label }}
        </button>
      }
    </div>
  </div>
}
