export function getNestedProperty<T>(obj: T, path: string): unknown | null {
  const parts = path.split(/\.|\[(\d+)\]/).filter(Boolean);
  let value: unknown = obj;

  for (const part of parts) {
    const index = parseInt(part, 10);
    if (
      value &&
      typeof value === 'object' &&
      (part in value || (!isNaN(index) && Array.isArray(value) && index < (value as any[]).length))
    ) {
      value = value[part];
      if (!value && !isNaN(index)) {
        value = value[index];
      }
    } else {
      return null;
    }
  }

  return value;
}
