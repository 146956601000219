import { Pipe, PipeTransform } from '@angular/core';

import { getNestedProperty } from '../../utils/get-nested-property.util';

@Pipe({
  name: 'filterBy',
  standalone: true,
})
export class FilterByPipe implements PipeTransform {
  transform<T>(arr: T[], propPath: string, searchTerm: string): T[] {
    if (!arr?.length || !searchTerm) {
      return arr;
    }

    const term = searchTerm.trim().toLowerCase();
    return arr.filter((item: T) => {
      const propValue = getNestedProperty(item, propPath);
      return propValue && `${propValue}`.trim().toLowerCase().includes(term);
    });
  }
}
